import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';

import { deleteApi, updateApi, getApiById} from '../../../api/keys';
import { getStatusesApi } from '../../../api/status';
import { getListApi as getListApiCategory } from '../../../api/category';


export default function KeysDetail() {
  const [item, setItem] = useState(null);
  const [itemOld, setItemOld] = useState(null);

  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  let [arrayCategories, setArrayCategories] = useState([]);
  let [arrayStatuses, setStatusesArray] = useState([]);

  const { id } = useParams();
  const tableName = 'keys';
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getApiById(id).then((result) => {
      setItem(result.data.message);
      setItemOld(result.data.message);
      setLoading(false);
    });

    async function getCategories() {
      const result = await getListApiCategory();
      if(result.status === 200) {
        setArrayCategories(result.data.message);
      }
    }

    if(arrayCategories.length === 0) {
      getCategories();
    }

    async function getStatuses() {
      const result = await getStatusesApi(tableName);
      if(result.status === 200) {
        setStatusesArray(result.data.message);
      }
    }

    if(arrayStatuses.length === 0) {
      getStatuses();
    }

    document.querySelector('body').classList.add('loaded');
  }, [id, setItem, setItemOld, arrayCategories, arrayStatuses]);

  async function updateItem(e) {
    e.preventDefault();

    let data = {
      key_code: e.target.key_code.value,
      category_licenses_name: e.target.category_licenses_name.value,
      status: e.target.status.value
    };

    const result = await updateApi(data, id);
    if (result.status === 200 && result.data.error === 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/cheie`);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  function deleteItem(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  async function deleteConfirm(result) {
    setOpenModal(false);
    if (result) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/cheie`);

      const checker = await deleteApi(id);
      if (checker.status === 200 && checker.data.error === 0) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/cheie`);
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current='Editeză Cheie'
            paths={[
              {
                name: 'Dashboard',
                url: '/dashboard',
              },
              {
                name: 'Panou pentru întreținere CEL',
                url: '#',
              },
              {
                name: 'Cheie',
                url: '#',
              },
              {
                name: 'Editeză cheie',
                url: '#',
              },
            ]}
          />

          <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={updateItem}
          >
            <Row>
              <Col>
                <Card className='card-modern card-big-info'>
                  <Card.Body>
                    <Row>
                      <Col lg='2-5' xl='1-5'>
                        <i className='card-big-info-icon bx bx-user-circle'></i>
                        <h2 className='card-big-info-title'>Informații cheie licență</h2>
                        <p className='card-big-info-desc'>
                          Adaugă aici informații pentru cheia licenței.
                        </p>
                      </Col>
                      <Col lg='3-5' xl='4-5'>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Categorie
                          </Col>
                          <Col lg={7} xl={6}>
                            <input
                              type='text'
                              list='idCategories'
                              name='category_licenses_name'
                              id='inputIdCategories'
                              placeholder="Categorie"
                              className='w-100 form-control-modern form-control'
                              defaultValue={arrayCategories.find((category) => category.id === itemOld.id_category_licenses)?.name}
                              required
                            />
                            <datalist id='idCategories'>
                            {/* show modules as options in input */}
                              {arrayCategories.map((item) => {
                                return <option key={item.name} value={item.name} />;
                              })}
                            </datalist>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Cheie
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='text'
                              maxLength='500'
                              name='key_code'
                              placeholder="Cheie"
                              className='w-100 form-control-modern'
                              defaultValue={itemOld?.key_code || ''}
                              onChange={(e) => {
                                setItem({ ...item, name: e.target.value });
                              }}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Status
                          </Col>
                          <Col lg={7} xl={6}>
                            <select
                              type='text'
                              list='statusEnum'
                              name='status'
                              id='inputStatusEnum'
                              placeholder="Status"
                              className='w-100 form-control-modern form-control'
                              defaultValue={itemOld?.status_id || ''}
                              onChange={(e) => {
                                setItem({ ...item, name: e.target.value})
                              }}
                              required
                            >
                              <option value='' disabled hidden> Select status </option>
                              {arrayStatuses.map((item) => {
                                return <option key={item.id} value={item.name}> {item.name} </option>;
                              })}
                            </select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='action-buttons'>
              <Col md='auto' className='col-6'>
                <Button
                  type='submit'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='primary'
                >
                  <i className='bx bx-save text-4 mr-2'></i> Salvează
                </Button>
              </Col>
              <Col md='auto' className='col-6 px-md-0 mt-0'>
                <Button
                  onClick={() => navigate(-1)}
                  className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                  variant='light'
                >
                  Înapoi
                </Button>
              </Col>
              <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                <Button
                  href='#delete'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='danger'
                  onClick={deleteItem}
                >
                  <i className='bx bx-trash text-4 mr-2'></i> Șterge
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
}
